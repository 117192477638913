import React from "react"
import Layout from "../components/layout"

const page404 = () => {

    return (
        <Layout>
            <div className="section">
                <div className="container">
                    <p>
                        There's nothing here dude.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default page404